import {createRouter, createWebHistory} from 'vue-router'
import { defineAsyncComponent } from "vue";

// Public pages
import PrivacyPolicyPage from "./public/PrivacyPolicy/PrivacyPolicy";
import ImprintPage from "./public/Imprint/Imprint";

// Settings
import LoadingPage from '@dutypay/settings-pages/src/components/LoadingPage';

import AccountSettings from "@dutypay/settings-pages/src/pages/AccountSettings";
const AccountInformation = defineAsyncComponent({
    loader: () => import('@dutypay/settings-pages/src/pages/AccountSettings/AccountInformation'),
    loadingComponent: LoadingPage
});
const SecuritySettings = defineAsyncComponent({
    loader: () => import('@dutypay/settings-pages/src/pages/AccountSettings/SecuritySettings'),
    loadingComponent: LoadingPage
});

import CompanySettings from "@dutypay/settings-pages/src/pages/CompanySettings";
const IntegrationSettings = defineAsyncComponent({
    loader: () => import('@dutypay/settings-pages/src/pages/CompanySettings/IntegrationSettings'),
    loadingComponent: LoadingPage
});
const CompanyInformation = defineAsyncComponent({
    loader: () => import('@dutypay/settings-pages/src/pages/CompanySettings/CompanyInformation'),
    loadingComponent: LoadingPage
});

import DeveloperSettings from "@dutypay/settings-pages/src/pages/DeveloperSettings";
const TranslationReport = defineAsyncComponent({
    loader: () => import('@dutypay/settings-pages/src/pages/DeveloperSettings/TranslationReport'),
    loadingComponent: LoadingPage
});
const TranslationEmailReportOverview = defineAsyncComponent({
    loader: () => import('@dutypay/settings-pages/src/pages/DeveloperSettings/TranslationEmailReportOverview'),
    loadingComponent: LoadingPage
});

import AdminSettings from "@dutypay/settings-pages/src/pages/AdminSettings";
const CompanyAdministration = defineAsyncComponent({
    loader: () => import('@dutypay/settings-pages/src/pages/AdminSettings/CompanyAdministration'),
    loadingComponent: LoadingPage
});
const UserAdministration = defineAsyncComponent({
    loader: () => import('@dutypay/settings-pages/src/pages/AdminSettings/UserAdministration'),
    loadingComponent: LoadingPage
});

// Pages
import {pages} from './pages.js';

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { left: 0, top: 0 }
        }
    },
    routes: [
        // ----------------
        //  PAGES
        // ----------------

        ...pages,

        // ----------------
        //  PUBLIC PAGES
        // ----------------
        {
            path: '/imprint',
            name: 'Imprint',
            component: ImprintPage,
            // meta: {
            //   public: true
            // }
        },
        {
            path: '/privacy-policy',
            name: 'Privacy Policy',
            component: PrivacyPolicyPage,
            // meta: {
            //   public: true
            // }
        },

        // ----------------
        //  SETTINGS
        // ----------------
        {
            path: '/account-settings',
            component: AccountSettings,
            children: [
                {
                    path: 'account-information',
                    component: AccountInformation
                },{
                    path: 'security-settings',
                    component: SecuritySettings
                }
            ]
        },
        {
            path: '/company-settings',
            component: CompanySettings,
            children: [{
                path: 'integrations',
                component: IntegrationSettings
            },{
                path: 'company-information',
                component: CompanyInformation
            }
            ]
        },
        {
            path: '/developer-settings',
            component: DeveloperSettings,
            children: [
                {
                    path: 'translation-report',
                    component: TranslationReport,
                    beforeEnter: (to, from, next) => {
                        next();
                    }
                },
                {
                    path: 'translation-email-report-overview',
                    component: TranslationEmailReportOverview,
                    beforeEnter: (to, from, next) => {
                        next();
                    }
                }
            ]
        },
        {
            path: '/admin-settings',
            component: AdminSettings,
            children: [
                {
                    path: 'companies',
                    component: CompanyAdministration,
                },
                {
                    path: 'users',
                    component: UserAdministration
                }
            ]
        }
    ]
});
